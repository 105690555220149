import React, { useState } from "react";
import { Package } from "lucide-react";
import ToggleButton from "../UIComponents/ToggleButton.jsx";
import IncreDreButtons from "../UIComponents/IncreDreButtons.jsx";

const EditMasking = ({ feature }) => {
  const [brushSize, setBrushSize] = useState(1);
  const [mode, setMode] = useState("Auto Select");
  return (
    <div className="bg-[#333537] text-white p-5 rounded-lg shadow-lg">
      <h3 className="text-lg  mb-4">Edit Masking</h3>
      <ToggleButton
        options={[
          { label: "Auto Select", value: "Auto Select" },
          { label: "Select Manually", value: "Select Manually" },
        ]}
        mode={mode}
        setMode={setMode}
      />

      <div className="">
        <label htmlFor="eraser" className="block text-base ">
          Erase/Restore:
        </label>
        <IncreDreButtons />
      </div>

      <div className="mb-4">
        <BrushSizeSlider brushSize={brushSize} setBrushSize={setBrushSize} />
      </div>

      <div
        className={` ${feature == "apparel-swap" ? "hidden" : ""} my-8 flex items-center justify-between`}
      >
        <span className="text-base ">Preview Product Cutout:</span>
        <Package />
      </div>

      <div className="flex justify-between gap-4 items-center mt-4">
        <button className="w-full py-4 bg-grayBG2 rounded-lg">Reset</button>
        <button className="w-full py-4 bg-generate-gradient rounded-lg">
          Done
        </button>
      </div>
    </div>
  );
};

export const BrushSizeSlider = ({ brushSize, setBrushSize }) => {
  return (
    <div className="mb-4">
      <label htmlFor="brushSize" className="text-white text-base">
        Brush Size:
      </label>
      <div className="flex items-center space-x-4 mt-2">
        <input
          id="brushSize"
          type="range"
          min="1"
          max="100"
          value={brushSize}
          onChange={(e) => setBrushSize(e.target.value)}
          className="w-full h-1 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-yellow-500"
          style={{
            WebkitAppearance: "none",
            appearance: "none",
            background: "#ccc",
            borderRadius: "5px",
            height: "4px",
          }}
        />
        <span className="text-gray-400 text-base">{brushSize}%</span>
      </div>
    </div>
  );
};

export default EditMasking;
