import { React, useRef, useState, useEffect } from "react";
import {
  Image,
  Sparkles,
  ChevronLeft,
  ChevronRight,
  LockKeyhole,
  Square,
  Smartphone,
  Monitor,
  AlignJustify,
  X,
} from "lucide-react";
import { DownloadButton } from "../../Utils/DownloadButton.jsx";
import FileUploader from "../FileUploader.jsx";
import ImagePlayGround from "./ImagePlayground.jsx";
import { useSelector } from "react-redux";
import RightDock from "../RightDock.jsx";
import Loader from "./Loader.jsx";
import ImageSettings from "./ImageSettings.jsx";
import ImageOutput from "./ImageOutput.jsx";
import { generateUniqueFilename } from "../../../utils/utils.jsx";

const ImageProcessor = ({
  title,
  images,
  modelImages,
  uploadTitle,
  onFaceSelect,
  onModelSelect,
  onRun,
  selectedFace,
  selectedModel,
  appliedFilter,
  isRunning,
  Header,
}) => {
  const [activeTab, setActiveTab] = useState("target");
  const [processedImage, setProcessedImage] = useState(null);
  const [showBackgroundRemoval, setShowBackgroundRemoval] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [showAllModelImages, setShowAllModelImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [faceImage, setFaceImage] = useState({});
  const [isResizeOpen, setResizeOpen] = useState(false);
  const [showLibrary, setShowLibrary] = useState(false);

  const [showGenerateBackground, setShowGenerateBackground] = useState(false);
  const [showBlurBackground, setShowBlurBackground] = useState(false);
  const [showResize, setShowResize] = useState(false);
  const [blurAmount, setBlurAmount] = useState(0);
  const [blurBackground, setBlurBackground] = useState(false);
  const [maskingConfig, setMaskingConfig] = useState(null);

  const handleMaskUpdate = (config) => {
    setMaskingConfig(config);
  };

  console.log("applied filter: ", appliedFilter);

  const handleBackgroundRemoval = (shouldRemove) => {
    if (shouldRemove) {
      onRun(true); // Run background removal
      setShowBackgroundRemoval(true);
    } else {
      setProcessedImage(null);
      setShowBackgroundRemoval(false);
    }
  };

  const resizeOptions = [
    { icon: Image, label: "Original", size: { width: 450, height: 600 } },
    { icon: Square, label: "Square", size: { width: 400, height: 400 } },
    { icon: Smartphone, label: "Portrait", size: { width: 400, height: 500 } },
    { icon: Monitor, label: "Landscape", size: { width: 500, height: 400 } },
  ];

  const [selectedSize, setSelectedSize] = useState({
    displayWidth: 450,
    displayHeight: 600,
    exportWidth: 1200,
    exportHeight: 1600,
  });

  const [modelImage, setModelImage] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentModelImageIndex, setCurrentModelImageIndex] = useState(0);
  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);
  const stageRef = useRef();

  const handleResize = (newDimensions) => {
    setSelectedSize(newDimensions);
  };

  // Create setStageRef function
  const setStageRef = (ref) => {
    stageRef.current = ref;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllImages(false);
  };

  console.log("face image:", faceImage.newImageUrl);

  const [libraryTab, setLibraryTab] = useState("photos");

  const [shadowProps, setShadowProps] = useState({
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowBlur: 5,
    shadowOpacity: 0.6,
    shadowOffsetX: 28,
    shadowOffsetY: -2,
  });
  const [showShadowOptions, setShowShadowOptions] = useState(false);

  const [canMerge, setCanMerge] = useState(false);

  const handleShadowPropsChange = (newShadowProps) => {
    setShadowProps(newShadowProps);
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllModelImages(false);
  };

  const handleShowMoreModels = () => {
    setShowAllModelImages(true);
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handleFaceReset = () => {
    setFaceImage({});
  };

  const handleModelReset = () => {
    setModelImage({});
  };

  const handleMergeImage = () => {
    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL({
        pixelRatio: 2,
      });
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = generateUniqueFilename(".png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    setCanMerge(
      !!faceImage.newImageUrl &&
        (!!appliedFilter || !!selectedModel.blobImageUrl),
    );
  }, [faceImage.newImageUrl, appliedFilter, selectedModel.blobImageUrl]);

  const renderLoadingPlaceholders = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="w-16 h-16 animate-pulse bg-gray-700 rounded-lg"
        />
      ));
  };

  return (
    <div
      className={`${isSidebarOpen ? "responsive-img-p" : ""}  transition-all duration-200`}
    >
      <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">{Header}</h1>
      <div className="flex flex-col lg:flex-row">
        {/* {isSectionVisible && ( */}
        <div
          className={`flex flex-col px-2 ${isSidebarOpen ? "ml-6" : ""}   py-4 overflow-auto lg:w-[700px] h-fit custom-scrollbar`}
        >
          {/* Tab content */}

          <div className=" mx-3">
            <div className=" ">
              {modelImage.newImageUrl ? (
                <div className="w-[200px] h-[200px] mx-auto">
                  <img
                    src={modelImage.newImageUrl}
                    alt="preview"
                    className="w-full h-full object-contain rounded-lg"
                  />
                </div>
              ) : (
                <div>
                  <FileUploader
                    title={"Upload "}
                    handleImageUpload={handleModelImageUpload}
                  />
                </div>
              )}
            </div>
            {modelImage.newImageUrl && (
              <div className="flex justify-center">
                <button
                  onClick={handleModelReset}
                  className="bg-[#333537] px-12 mt-2 py-2 rounded-lg text-white uppercase"
                >
                  Clear
                </button>
              </div>
            )}
          </div>
          {/* left dock */}
          <div className="mt-6 mx-2">
            <ImageSettings
              images={images}
              currentImageIndex={currentImageIndex}
              selectedFace={modelImage.newImageUrl}
              handleFaceImageSelect={handleFaceImageSelect}
              handleFaceImageUpload={handleFaceImageUpload}
              handleReset={handleFaceReset}
              renderLoadingPlaceholders={renderLoadingPlaceholders}
              onShadowPropsChange={handleShadowPropsChange}
              blurAmount={blurAmount}
              setBlurAmount={setBlurAmount}
              blurBackground={blurBackground}
              setBlurBackground={setBlurBackground}
              onResize={handleResize}
              onRun={handleBackgroundRemoval}
              showBackgroundRemoval={showBackgroundRemoval}
              onUpdateMask={handleMaskUpdate} // Add this
              originalImage={selectedModel?.blobImageUrl}
            />
          </div>
        </div>
        {/* output component */}
        <ImageOutput
          isRunning={isRunning}
          appliedFilter={appliedFilter}
          selectedSize={{
            width: selectedSize.displayWidth,
            height: selectedSize.displayHeight,
          }}
          faceImage={faceImage}
          setStageRef={setStageRef}
          shadowProps={shadowProps}
          selectedModel={selectedModel}
          onMergeImage={handleMergeImage}
          blurAmount={blurAmount}
          blurBackground={blurBackground}
          showBackgroundRemoval={showBackgroundRemoval}
          maskingConfig={maskingConfig} // Add this
          originalImage={selectedModel?.blobImageUrl}
        />

        <RightDock
          onRun={onRun}
          selectedFace={selectedFace}
          selectedModel={selectedModel}
          isRunning={isRunning}
          outputImage={appliedFilter}
          onMergeImage={handleMergeImage}
          canMerge={canMerge}
          feature={"bg-swap"}
        />
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageProcessor;
