import React, { useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

const PhotoLibrary = ({
  images,
  handleFaceImageSelect,
  renderLoadingPlaceholders,
}) => {
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFace, setSelectedFace] = useState(null);

  const handleImageClick = (img) => {
    setSelectedFace(img);
    handleFaceImageSelect(img);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="rounded-lg bg-grayBG2 py-2 flex items-center justify-center "
      >
        <div
          className="grid grid-cols-3 h-[90px] gap-4 overflow-y-auto"
          style={{
            maxHeight: "300px",
            scrollbarWidth: "thin",
            scrollbarColor: "#666 #222226",
          }}
        >
          {images && images.length > 0 ? (
            images.map((img, index) => (
              <div
                key={index}
                className={`aspect-square w-[80px] h-[80px] relative rounded-lg overflow-hidden bg-[#2A2A2E] 
                  ${selectedFace === img ? "ring-2 ring-green-400" : ""}`}
              >
                <img
                  src={img}
                  className="w-full h-full object-cover cursor-pointer"
                  alt={`Image ${index + 1}`}
                  onClick={() => handleImageClick(img)}
                />
              </div>
            ))
          ) : (
            <>{renderLoadingPlaceholders()}</>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PhotoLibrary;
