import React from "react";
import Loader from "../BackgroundSwap/Loader";

const ApparelSwapMiddle = ({ isRunning, outputImage, openFullscreenImage }) => {
  return (
    <div className="w-full lg:min-h-screen bg-black">
      <div className="flex flex-col items-center justify-center md:flex-row gap-4">
        <div className="w-full relative rounded-lg py-4">
          <div className="aspect-square lg:w-[550px] lg:h-[600px] mx-auto mb-4">
            {isRunning ? (
              <div className="w-full h-full">
                <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                  <Loader />
                </div>
              </div>
            ) : outputImage ? (
              <img
                src={outputImage}
                alt="filtered"
                className="w-full h-full object-contain rounded-lg cursor-pointer"
                onClick={() => openFullscreenImage(outputImage)}
              />
            ) : (
              <div className="bg-[#333537] p-4 h-[600px] flex flex-col items-center justify-center rounded-lg">
                {/* Optional placeholder content */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApparelSwapMiddle;
