import React, { useState } from "react";
import { Edit2, X, Plus, Share2 } from "lucide-react";

const GarmentDetailsUI = ({ garmentDetails, selectedImage }) => {
  if (!garmentDetails || !selectedImage) return null;

  const [editableDetails, setEditableDetails] = useState({});
  const [details, setDetails] = useState(garmentDetails?.product_details || {});
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  const handleEditDetail = (key) => {
    setEditableDetails((prev) => ({
      ...prev,
      [key]: details[key.toLowerCase()] || "",
    }));
  };

  const handleSaveDetail = (key) => {
    const newValue = editableDetails[key];
    if (newValue !== undefined) {
      setDetails((prev) => ({
        ...prev,
        [key.toLowerCase()]: newValue,
      }));
      setEditableDetails((prev) => {
        const newState = { ...prev };
        delete newState[key];
        return newState;
      });
    }
  };

  const handleKeyDown = (e, key) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSaveDetail(key);
    }
  };

  const handleDeleteDetail = (keyToDelete) => {
    const newDetails = { ...details };
    delete newDetails[keyToDelete.toLowerCase()];
    setDetails(newDetails);
  };

  const handleAddDetail = () => {
    if (newKey && newValue) {
      setDetails((prev) => ({
        ...prev,
        [newKey.toLowerCase()]: newValue,
      }));
      setNewKey("");
      setNewValue("");
      setIsAdding(false);
    }
  };

  const renderDetailCard = (key, value, isPDF = false) => (
    <div key={key} className="bg-[#1e1e1e] rounded-lg p-4 relative group">
      <div className="flex justify-between items-center">
        <span className="text-purple-400">{key}:</span>
        {!isPDF && (
          <div className="flex gap-2">
            <button
              onClick={() => handleEditDetail(key)}
              className="text-gray-400 hover:text-white"
            >
              <Edit2 size={16} />
            </button>
            <button
              onClick={() => handleDeleteDetail(key)}
              className="text-gray-400 hover:text-white"
            >
              <X size={16} />
            </button>
          </div>
        )}
      </div>
      <div className="mt-2 text-gray-300">
        {!isPDF && editableDetails[key] !== undefined ? (
          <input
            value={editableDetails[key]}
            onChange={(e) =>
              setEditableDetails((prev) => ({ ...prev, [key]: e.target.value }))
            }
            onKeyDown={(e) => handleKeyDown(e, key)}
            onBlur={() => handleSaveDetail(key)}
            className="w-full bg-[#2a2a2a] text-white p-1 rounded"
            autoFocus
          />
        ) : (
          value || "Not specified"
        )}
      </div>
    </div>
  );

  const renderAddCard = () => (
    <div className="bg-[#1e1e1e] rounded-lg p-4">
      {isAdding ? (
        <div className="space-y-2">
          <input
            placeholder="Key"
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
            className="w-full bg-[#2a2a2a] text-white p-2 rounded mb-2"
          />
          <input
            placeholder="Value"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className="w-full bg-[#2a2a2a] text-white p-2 rounded mb-2"
          />
          <div className="flex justify-end gap-2">
            <button
              onClick={() => setIsAdding(false)}
              className="text-gray-400 hover:text-white"
            >
              <X size={16} />
            </button>
            <button
              onClick={handleAddDetail}
              className="text-purple-400 hover:text-purple-300"
            >
              Add
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setIsAdding(true)}
          className="w-full h-full flex items-center justify-center text-gray-400 hover:text-white"
        >
          <Plus size={24} />
        </button>
      )}
    </div>
  );

  return (
    <div className="bg-black text-white garment-details-container">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">
          <h2 className="text-xl mb-2">Image</h2>
          <div className="border h-full border-gray-800 rounded-lg p-4">
            <div className="flex items-center justify-center">
              {selectedImage && (
                <img
                  src={selectedImage.newImageUrl || selectedImage.newImage}
                  alt="Garment"
                  className="w-full h-auto object-contain"
                />
              )}
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-xl">Garment Description</h2>
          </div>
          <div className="border border-gray-800 h-full rounded-lg p-4">
            <p className="text-gray-300">
              {garmentDetails.full_description || "No description available"}
            </p>
          </div>
        </div>

        <div className="col-span-3 mt-8">
          <h2 className="text-xl my-2">Garment Details</h2>
          {/* PDF version */}
          <div className="border border-gray-800 rounded-lg p-4 pdf-content">
            <div className="grid grid-cols-3 gap-4">
              {Object.entries(details).map(([key, value]) =>
                renderDetailCard(key, value, true),
              )}
            </div>
          </div>
          {/* Browser version */}
          <div className="border h-[330px] overflow-scroll border-gray-800 rounded-lg p-4 browser-content">
            <div className="grid grid-cols-3 gap-4">
              {renderAddCard()}
              {Object.entries(details).map(([key, value]) =>
                renderDetailCard(key, value, false),
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GarmentDetailsUI;
