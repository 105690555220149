import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Image, Line } from "react-konva";
import Konva from "konva";

// Extended RemoveBG component
const RemoveBG = ({
  onClose,
  onRun,
  modelImage,
  showBackgroundRemoval,
  originalImage,
  onUpdateMask,
}) => {
  const [removeBackground, setRemoveBackground] = useState(false);
  const [libraryTab, setLibraryTab] = useState("Eraser");
  const [brushSize, setBrushSize] = useState(20);
  const [isDrawing, setIsDrawing] = useState(false);

  console.log("model", modelImage);

  useEffect(() => {
    if (!modelImage) {
      setRemoveBackground(false);
    }
  }, [modelImage]);

  useEffect(() => {
    setRemoveBackground(showBackgroundRemoval);
  }, [showBackgroundRemoval]);

  const handleToggle = () => {
    const newState = !removeBackground;
    setRemoveBackground(newState);
    onRun(newState);
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setRemoveBackground(false);
    onRun(false);
    onUpdateMask && onUpdateMask(null);
  };

  const handleClose = (e) => {
    e.stopPropagation();

    onClose();
  };

  const handleBrushSizeChange = (e) => {
    setBrushSize(parseInt(e.target.value));
    if (onUpdateMask) {
      onUpdateMask({ brushSize, mode: libraryTab.toLowerCase() });
    }
  };

  return (
    <div className="mt-4 bg-grayBG rounded-lg">
      <div className="mb-4">
        <label
          htmlFor="remove-background"
          className="block text-sm font-light mb-2"
        >
          Remove Background
        </label>
        <div
          className={`w-14 h-8 bg-gray-400 rounded-full p-1 cursor-pointer
    ${!modelImage ? "opacity-50 cursor-not-allowed" : ""}
    ${removeBackground ? "justify-end" : "justify-start"}`}
          onClick={modelImage ? handleToggle : undefined}
        >
          <div
            className={`w-6 h-6 bg-black rounded-full transition-transform duration-300 
      ${removeBackground ? "transform translate-x-6" : ""}`}
          />
        </div>
      </div>

      {/* erase/restore features  */}
      {/* <div>
        <div className="flex bg-black p-1 items-center justify-around rounded-2xl mb-2">
          <button
            className={`px-4 py-1 ${
              libraryTab === "Eraser" ? "bg-generate-gradient" : "hover:bg-grayBG"
            } transition-all duration-200 rounded-2xl`}
            onClick={() => {
              setLibraryTab("Eraser");
              onUpdateMask && onUpdateMask({ brushSize, mode: 'eraser' });
            }}
          >
            Erase
          </button>
          <button
            className={`px-4 py-1 ${
              libraryTab === "Restore" ? "bg-generate-gradient" : "hover:bg-grayBG"
            } transition-all duration-200 rounded-2xl`}
            onClick={() => {
              setLibraryTab("Restore");
              onUpdateMask && onUpdateMask({ brushSize, mode: 'restore' });
            }}
          >
            Restore
          </button>
        </div>
        <div>
          <h1 className="my-4">Brush Size: {brushSize}px</h1>
          <div className="flex-1 flex w-full">
            <input
              type="range"
              min={5}
              max={50}
              value={brushSize}
              onChange={handleBrushSizeChange}
              className="w-full appearance-none bg-transparent 
                [&::-webkit-slider-thumb]:appearance-none 
                [&::-webkit-slider-thumb]:h-4 
                [&::-webkit-slider-thumb]:w-4 
                [&::-webkit-slider-thumb]:bg-yellow-500 
                [&::-webkit-slider-thumb]:rounded-full 
                [&::-webkit-slider-thumb]:relative 
                [&::-webkit-slider-thumb]:-top-1.5 
                [&::-webkit-slider-thumb]:cursor-pointer
                [&::-webkit-slider-runnable-track]:bg-white 
                [&::-webkit-slider-runnable-track]:h-1 
                [&::-webkit-slider-runnable-track]:rounded-full"
            />
          </div>
        </div>
      </div> */}

      <div className="mt-4 flex text-white items-center gap-4 justify-between">
        <button
          className="w-full py-4 rounded-lg bg-grayBG2"
          onClick={handleReset}
        >
          Reset
        </button>
        <button
          className="w-full py-4 rounded-lg bg-generate-gradient"
          onClick={handleClose}
        >
          Done{" "}
        </button>
      </div>
    </div>
  );
};

export default RemoveBG;
