import React, { useState } from "react";
import { X, ChevronDown, ChevronUp } from "lucide-react";
import ImageSection from "../ImageSection.jsx";
import EditMasking from "../EditMasking.jsx";

const ApparelSwapLeft = ({
  sourceTitle,
  targetTitle,
  images,
  modelImages,
  onFaceSelect,
  onModelSelect,
  isSidebarOpen,
  openFullscreenImage,
}) => {
  const [faceImage, setFaceImage] = useState({});
  const [modelImage, setModelImage] = useState({});
  const [showMasking, setShowMasking] = useState(false);

  const toggleMasking = () => setShowMasking((prev) => !prev);

  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const renderLoadingPlaceholders = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="w-16 h-16 animate-pulse bg-gray-700 rounded-lg"
        />
      ));
  };

  return (
    <div
      className={`flex flex-col px-2 relative ${isSidebarOpen ? "ml-6" : ""} py-4 overflow-auto lg:w-[700px] h-fit`}
    >
      {/* Source Image Section */}
      {faceImage?.newImageUrl ? (
        <div className=" px-2 relative">
          <h1 className="text-white my-4">{sourceTitle}</h1>
          <div
            className="w-[150px] h-[150px] relative border border-[#3e3e3e] rounded-lg mb-4"
            onClick={() => openFullscreenImage(faceImage.newImageUrl)}
          >
            <img
              src={faceImage.newImageUrl}
              alt="preview"
              className="w-full h-full object-contain rounded-lg cursor-pointer"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                setFaceImage({});
              }}
              className="absolute top-0 right-0 rounded-full p-1"
            >
              <X color="red" size={16} />
            </button>
          </div>

          <div className="text-white flex justify-between items-center mb-2  cursor-pointer">
            <h1 className="" onClick={toggleMasking}>
              Edit Masking
            </h1>
            <div className="flex items-center gap-3">
              {showMasking ? (
                <ChevronUp
                  className="hover:text-grayBG3 transition-all duration-300"
                  onClick={toggleMasking}
                />
              ) : (
                <ChevronDown
                  className="hover:text-grayBG3 transition-all duration-300"
                  onClick={toggleMasking}
                />
              )}
            </div>
          </div>
          {showMasking && <EditMasking feature={"apparel-swap"} />}
        </div>
      ) : (
        <ImageSection
          title={sourceTitle}
          images={images}
          selectedImage={faceImage}
          onImageSelect={handleFaceImageSelect}
          onImageUpload={handleFaceImageUpload}
          onReset={() => setFaceImage({})}
          renderLoadingPlaceholders={renderLoadingPlaceholders}
          type="source"
        />
      )}
      <div className="border-b border-[#484848] pb-4"></div>

      {/* Target Image Section */}
      {modelImage?.newImageUrl ? (
        <div className="ml-6 relative">
          <h1 className="text-white my-4">{targetTitle}</h1>
          <div
            className="w-[150px] h-[150px] relative border border-[#3e3e3e] rounded-lg mb-4"
            onClick={() => openFullscreenImage(modelImage.newImageUrl)}
          >
            <img
              src={modelImage.newImageUrl}
              alt="preview"
              className="w-full h-full object-contain rounded-lg cursor-pointer"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                setModelImage({});
              }}
              className="absolute top-0 right-0 rounded-full p-1"
            >
              <X color="red" size={16} />
            </button>
          </div>
        </div>
      ) : (
        <ImageSection
          title={targetTitle}
          images={modelImages}
          selectedImage={modelImage}
          onImageSelect={handleModelImageSelect}
          onImageUpload={handleModelImageUpload}
          onReset={() => setModelImage({})}
          renderLoadingPlaceholders={renderLoadingPlaceholders}
          type="target"
        />
      )}
    </div>
  );
};

export default ApparelSwapLeft;
