import React, { useState } from "react";
import { ChevronDown, Pencil } from "lucide-react";

export default function GenerateField({ title, gen }) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const predefinedPrompts =
    gen === "model"
      ? [
          "Indian female model wearing a traditional red silk saree with gold border",
          "Professional male model in a tailored navy business suit",
          "Female model in casual streetwear with urban background",
          "South Asian model in contemporary fusion wear",
          "Model showcasing traditional Lehenga with detailed embroidery",
          "Female model in western formal wear with minimal makeup",
          "Male model in traditional Sherwani with detailed work",
          "Model in Indo-western outfit with modern accessories",
          "Female model in designer saree with contemporary styling",
          "Model in professional business attire in office setting",
          "Female model in bridal wear with traditional jewelry",
          "Male model in casual ethnic wear for festive season",
          "Model showcasing sustainable fashion in natural setting",
        ]
      : [
          "A modern outdoor scene with soft lighting",
          "A cozy minimalist living room at sunset",
          "A serene Japanese garden with cherry blossoms",
          "A bustling city street at night with neon lights",
          "A tranquil mountain landscape with morning mist",
          "A vintage cafe interior with warm ambient lighting",
          "A futuristic cyberpunk cityscape at dusk",
          "A peaceful beach scene with pastel sky colors",
          "An enchanted forest with magical lighting elements",
          "A contemporary art studio with natural light",
          "A rustic farmhouse kitchen at dawn",
          "A geometric abstract pattern with soft gradients",
          "A rainy city street with reflective puddles",
        ];

  return (
    <div className="">
      <h1 className="text-white text-center mb-4">{title}</h1>

      {/* Text Input Area */}
      <div className="relative bg-grayBG rounded-lg p-4 mb-4">
        <textarea
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="w-full bg-transparent text-gray-300 resize-none outline-none"
          rows={3}
        />
        <button className="absolute bottom-4 right-4 text-gray-400">
          <Pencil size={20} />
        </button>
      </div>

      {/* Or Divider */}
      <div className="text-center text-gray-400 my-4">or</div>

      {/* Predefined Prompts Dropdown */}
      <div className="relative">
        <div className="mb-2 text-gray-400">Select from Predefined Prompts</div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full text-sm bg-grayBG p-4 rounded-lg text-gray-400 flex justify-between items-center"
        >
          Select Prompt
          <ChevronDown size={20} />
        </button>

        {/* Dropdown Menu */}
        {isOpen && (
          <div className="absolute w-full z-50 mt-2 bg-grayBG rounded-lg h-[200px] overflow-scroll">
            {predefinedPrompts.map((prompt, index) => (
              <div
                key={index}
                className="p-4 text-gray-400 text-sm hover:bg-gray-700 cursor-pointer"
                onClick={() => {
                  setInputValue(prompt);
                  setIsOpen(false);
                }}
              >
                {prompt}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center justify-center">
        <button className="w-fit text-center bg-generate-gradient text-white py-2 px-8 rounded-lg mt-6 hover:opacity-90 transition-opacity">
          Generate
        </button>
      </div>
    </div>
  );
}
