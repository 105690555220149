import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Home,
  FolderOpen,
  Settings,
  Bell,
  User,
  HelpCircle,
  CircleDollarSign,
  Trash2,
  Crown,
} from "lucide-react";
// import CrownWhite from "../../assets/CownWhite.svg";
import { useState } from "react";
import "./Navbar.css";

const TopNavbar = ({ profileData, handleLogout }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const menuItems = [
    { icon: User, label: "My Profile", link: "/dashboard/profile" },
    { icon: Settings, label: "Settings", link: "/settings" },
    { icon: CircleDollarSign, label: "Pricing", link: "/dashboard/pricing" },
    { icon: HelpCircle, label: "Help Center", link: "/help" },
    {
      icon: Trash2,
      label: "Log Out",
      onClick: () => {
        handleLogout();
        setDropDownOpen(false);
      },
    },
  ];

  return (
    <div className="fixed top-0 pt-2 top-navbar left-0 text-white right-0 h-16 bg-[#222226] shadow-sm z-10 flex items-center justify-between px-6">
      <div className="flex items-center"></div>
      <div className="flex items-center gap-8">
        <button className="px-2 py-1 border flex gap-2 items-center rounded-lg hover:bg-gray-900">
          {/* <img src={Crown} className="w-5 h-5" /> */}
          <Crown className="w-5 h-5" fill="white" />
          <p className="text-sm">Upgrade</p>
        </button>
        <button className="p-2 rounded-full hover:bg-gray-900">
          <Bell className="w-5 h-5" />
        </button>
        {/* <button className="p-2 rounded-full hover:bg-gray-900">
          <Settings className="w-5 h-5" />
        </button> */}
        <div className="relative">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => setDropDownOpen(!dropDownOpen)}
          >
            {profileData?.profile_image ? (
              <img
                src={profileData.profile_image}
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
            ) : (
              <User />
            )}
            <span className="text-sm font-medium">
              {profileData ? profileData.first_name : "Unknown user"}
            </span>
            <svg
              className={`w-4 h-4 transform transition-transform ${dropDownOpen ? "rotate-180" : ""}`}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          </div>

          {dropDownOpen && (
            <div className="origin-top-right absolute right-0 mt-2 w-80 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 overflow-hidden">
              <div className="px-6 py-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
                <p className="text-lg font-semibold">
                  {profileData?.first_name || "User"}
                </p>
                <p className="text-sm opacity-80">{profileData?.email || ""}</p>
              </div>
              <div className="py-2">
                {menuItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.link}
                    onClick={() => {
                      setDropDownOpen(false);
                      if (item.onClick) item.onClick();
                    }}
                    className="flex items-center px-6 py-3 text-sm text-gray-800 hover:bg-gray-50 transition duration-300 ease-in-out group"
                  >
                    <item.icon className="w-5 h-5 mr-3 text-gray-400 group-hover:text-blue-500 transition-colors duration-300" />
                    <span
                      className="group-hover:text-blue-600 transition-colors duration-300"
                      style={{ display: "block" }}
                    >
                      {item.label}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const StudioNavbar = ({ logo, profileData, handleLogout }) => {
  const location = useLocation();

  const navItems = [
    { icon: Home, label: "Home", path: "/dashboard/home", enabled: true },
    { icon: User, label: "Profile", path: "/dashboard/profile", enabled: true },
    { icon: FolderOpen, label: "Projects", path: "/", enabled: false },
    { icon: Settings, label: "Settings", path: "/", enabled: false },
    { icon: Bell, label: "Notifications", path: "/", enabled: false },
    { icon: HelpCircle, label: "Help", path: "/", enabled: false },
    { icon: Trash2, label: "Trash", path: "/", enabled: false },
  ];

  const displayContent =
    location.pathname === "/dashboard/home" ||
    location.pathname === "/dashboard/profile";

  return (
    <>
      <nav
        className={`${displayContent ? "block" : "hidden"} studio-navbar h-screen w-[150px] z-50 fixed left-0 top-0 bg-gradient-t-b lg:flex flex-col items-center py-4 shadow-lg`}
      >
        <Link to="/dashboard/home" className="mb-[50px]">
          <img src={logo} alt="Logo" className="w-28 h-auto" />
        </Link>

        <div className="flex flex-col items-center space-y-6 flex-grow">
          {navItems.map(({ icon: Icon, label, path, enabled }) => (
            <div
              key={label}
              className={`flex flex-col items-center justify-center w-full p-2 ${
                enabled
                  ? "text-white hover:bg-white/10 cursor-pointer"
                  : "text-gray-500 cursor-not-allowed"
              } transition-colors rounded-md ${
                location.pathname === path && enabled ? "bg-white/20" : ""
              }`}
            >
              {enabled ? (
                <Link
                  to={path}
                  className="flex flex-col items-center justify-center w-full"
                >
                  <div className="p-1">
                    <Icon className="w-6 h-6 mb-1" />
                  </div>
                  <p className="text-xs">{label}</p>
                </Link>
              ) : (
                <>
                  <div className="p-1">
                    <Icon className="w-6 h-6 mb-1" />
                  </div>
                  <p className="text-xs">{label}</p>
                </>
              )}
            </div>
          ))}
        </div>
      </nav>
      <TopNavbar profileData={profileData} handleLogout={handleLogout} />
      <main className="ml-[150px] mt-16"></main>
    </>
  );
};

export default StudioNavbar;
