import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Image as ImageIcon, Pencil, X } from "lucide-react";
import { generateGarmentDetails } from "../../../api/modelsApi";
import ImageSection from "../../ImageProcessing/ImageSection";
import RightDock from "../../ImageProcessing/RightDock";
import FullscreenImageModal from "../../ImageProcessing/FullScreenImageModal";
import GarmentDetailsDisplay from "./GarmentsDetailsDisplay";
import { updateTabState } from "../../../redux/studioSlice";
import Loader from "../../ImageProcessing/BackgroundSwap/Loader";
import { getFullModelImages } from "../../../api/statics";

const DescriptionGenerator = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.studio.DESC_GEN);
  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  const [selectedImage, setSelectedImage] = useState({
    newImage: null,
    newImageUrl: state?.selectedImage?.newImageUrl || "",
  });
  const [garmentDetails, setGarmentDetails] = useState(
    state?.garmentDetails || null,
  );
  const [isRunning, setIsRunning] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [error, setError] = useState(null);
  const [clothImages, setClothImages] = useState(state?.clothImages || []);
  const [descriptionLength, setDescriptionLength] = useState(100);
  const [brand, setBrand] = useState("Only");
  const [isEditingBrand, setIsEditingBrand] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      if (clothImages.length === 0) {
        try {
          const clothImageData = await getFullModelImages();
          const clothImageUrls = clothImageData.map((image) => image.file);
          setClothImages(clothImageUrls);
        } catch (error) {
          console.error("Error fetching cloth images:", error);
        }
      }
    };
    fetchImages();
  }, [clothImages.length]);

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setSelectedImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      setGarmentDetails(null);
      setError(null);
    }
  };

  const handleImageSelect = (imageUrl) => {
    // Convert the URL to a Blob when selecting from library
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        setSelectedImage({
          newImage: blob, // Store the blob as newImage
          newImageUrl: imageUrl,
        });
        setGarmentDetails(null);
        setError(null);
      })
      .catch((error) => {
        console.error("Error converting URL to blob:", error);
        setError("Failed to process selected image");
      });
  };

  const handleRun = async () => {
    if (isRunning || !selectedImage.newImageUrl) return;

    setIsRunning(true);
    setError(null);

    try {
      const details = await generateGarmentDetails(
        selectedImage.newImage || selectedImage.newImageUrl,
        descriptionLength,
        brand,
      );
      setGarmentDetails(details);
    } catch (error) {
      setError(error.message || "Failed to analyze garment");
    } finally {
      setIsRunning(false);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const renderLoadingPlaceholders = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="w-16 h-16 animate-pulse bg-gray-700 rounded-lg"
        />
      ));
  };

  return (
    <div
      className={`${
        isSidebarOpen ? "responsive-img-p" : ""
      } mt-16 bg-[#222226] transition-all duration-200 rounded-xl`}
    >
      <div className="flex flex-col lg:flex-row">
        <div
          className={`flex flex-col px-2 relative ${isSidebarOpen ? "ml-6" : ""}  py-4 overflow-auto lg:w-[700px] h-screen`}
        >
          {selectedImage.newImageUrl ? (
            <div className="ml-6 relative">
              <h1 className="text-white my-4">Target Image</h1>
              <div
                className="w-[150px] h-[150px] relative bg-transparent border border-[#4d4d4d] rounded-lg mb-4"
                onClick={() => openFullscreenImage(selectedImage.newImageUrl)}
              >
                <img
                  src={selectedImage.newImageUrl}
                  alt="preview"
                  className="w-full h-full object-contain rounded-lg cursor-pointer"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedImage({ newImage: null, newImageUrl: "" });
                  }}
                  className="absolute top-0 right-0 rounded-full p-1"
                >
                  <X color="red" size={16} />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <ImageSection
                title="Target Image"
                images={clothImages}
                onImageSelect={handleImageSelect}
                onImageUpload={handleImageUpload}
                renderLoadingPlaceholders={renderLoadingPlaceholders}
                type="source"
              />
            </div>
          )}
          <div>
            <div className="flex items-center justify-between border-t border-b border-[#585858] py-2 text-white">
              {isEditingBrand ? (
                <input
                  type="text"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  onBlur={() => setIsEditingBrand(false)}
                  className="w-full bg-transparent border-b border-white focus:outline-none"
                  autoFocus
                />
              ) : (
                <div className="flex items-center justify-between w-full">
                  <div className="flex">
                    <h1 className="mr-2">Brand: </h1>
                    <span className="font-semibold">{brand}</span>
                  </div>
                  <button
                    onClick={() => setIsEditingBrand(true)}
                    className="ml-2"
                  >
                    <Pencil size={16} />
                  </button>
                </div>
              )}
            </div>
            <div className=" mt-4">
              <h1 className="text-white mb-2">Description Length</h1>
              <div className="flex flex-col gap-2">
                {[50, 100, 150].map((wordCount) => (
                  <div key={wordCount} className="flex items-center">
                    <input
                      type="radio"
                      id={`length-${wordCount}`}
                      name="descriptionLength"
                      value={wordCount}
                      checked={state.descriptionLength === wordCount}
                      className="appearance-none h-4 w-4 rounded-full fill-white border border-gray-400 bg-transparent checked:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
                    />
                    <label
                      htmlFor={`length-${wordCount}`}
                      className="ml-2 text-white"
                    >
                      {wordCount} words
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* output */}
        <div className="w-full min-h-screen bg-black">
          <div className="flex flex-col items-center justify-center md:flex-row gap-4">
            <div className="w-full relative rounded-lg py-4">
              <div className="aspect-square mx-auto px-6 mb-4">
                {isRunning ? (
                  <div className="w-full h-[600px]">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                      <Loader />
                    </div>
                  </div>
                ) : garmentDetails ? (
                  <div className="w-full h-full rounded-lg p-4 overflow-auto">
                    <GarmentDetailsDisplay
                      garmentDetails={garmentDetails}
                      selectedImage={selectedImage}
                    />
                  </div>
                ) : (
                  <div className="bg-transparent   p-4 h-[600px] flex flex-col items-center justify-center rounded-lg">
                    <h1 className="text-sm text-gray-500">
                      Generated garment details will display here!
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <RightDock
          onRun={handleRun}
          selectedFace={{
            blobImage: selectedImage.newImage,
            blobImageUrl: selectedImage.newImageUrl,
          }}
          selectedModel={null}
          isRunning={isRunning}
          outputImage={garmentDetails}
          feature="description-gen"
          garmentDetails={garmentDetails}
        />

        <FullscreenImageModal
          imageUrl={fullscreenImage}
          onClose={closeFullscreenImage}
        />
      </div>
      {error && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-600 text-white p-2 rounded">
          {error}
        </div>
      )}
    </div>
  );
};

export default DescriptionGenerator;
