import React from "react";
import { Download } from "lucide-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const DescriptionDownload = ({ garmentDetails, skuId }) => {
  const downloadPDF = async () => {
    const element = document.querySelector(".garment-details-container");
    if (!element) return;

    try {
      // Before capturing, show PDF content and hide browser content
      const pdfContent = document.querySelector(".pdf-content");
      const browserContent = document.querySelector(".browser-content");

      if (pdfContent && browserContent) {
        pdfContent.style.display = "block";
        browserContent.style.display = "none";
      }

      // Create canvas from the HTML content
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#000000",
        // Ensure we capture the full height
        height: element.scrollHeight,
        windowHeight: element.scrollHeight,
      });

      // Reset display states
      if (pdfContent && browserContent) {
        pdfContent.style.display = "none";
        browserContent.style.display = "block";
      }

      // Create PDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width, canvas.height],
      });

      // Add the canvas as an image to the PDF
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

      // Download the PDF
      pdf.save(`garment-details-${skuId}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <button
      onClick={downloadPDF}
      disabled={!garmentDetails}
      className="text-black bg-customYellow w-full py-3 px-8 rounded-md cursor-pointer hover:opacity-70 transition-opacity flex gap-2 items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
    >
      <Download size={16} />
      <span className="text-sm">Download PDF</span>
    </button>
  );
};

export default DescriptionDownload;
