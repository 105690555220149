import { React, useState } from "react";
import { Image, Sparkles } from "lucide-react";
import { DownloadButton } from "../../Utils/DownloadButton.jsx";
import FileUploader from "../FileUploader.jsx";
import { useSelector } from "react-redux";
import FilterForm from "../FilterForm.jsx";
import GenerateField from "./GenerateField.jsx";
import ImagePlaceholder from "./ImagePlaceholder.jsx";
import RightDock from "../RightDock.jsx";

const ImageProcessorGenerator = ({
  title,
  uploadTitle,
  onRun,
  appliedFilter,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
  gen,
}) => {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const renderLoadingPlaceholders = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="w-16 h-16 animate-pulse bg-gray-700 rounded-lg"
        />
      ));
  };

  return (
    <div
      className={`${isSidebarOpen ? "responsive-img-p" : ""} bg-[#222226] transition-all duration-200  rounded-xl`}
    >
      <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">{Header}</h1>
      <div className="flex flex-col  lg:flex-row ">
        <div
          className={`flex flex-col px-2  ${isSidebarOpen ? "ml-6" : ""}  py-4 overflow-auto lg:w-[700px] h-screen`}
        >
          <GenerateField title={title} gen={gen} />
        </div>

        <div className="w-full min-h-screen bg-black">
          <div className="flex flex-co items-center justify-center md:flex-row gap-4">
            <div className="w-full relative rounded-lg py-4">
              <div className="aspect-square mx-auto w-[550px] h-[600px]  mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                      <h1 className="text-xl text-yellow-400">
                        Generation in Process
                      </h1>
                    </div>
                    <div className="loader"></div>
                  </div>
                ) : appliedFilter ? (
                  <img
                    src={appliedFilter}
                    alt="filtered"
                    className="w-full h-full object-contain rounded-lg cursor-pointer"
                    onClick={() => openFullscreenImage(appliedFilter)}
                  />
                ) : (
                  <ImagePlaceholder />
                )}
              </div>
            </div>
          </div>
        </div>

        <RightDock
          onRun={() => {}}
          selectedFace={{ blobImageUrl: null, blobImage: null }}
          selectedModel={{ blobImage: null }}
          isRunning={false}
          outputImage={null}
          onMergeImage={() => {}}
          canMerge={false}
          feature=""
          garmentDetails={null}
        />

        {/* Your existing modals remain the same */}
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageProcessorGenerator;
