import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import RightDock from "./RightDock.jsx";
import FullscreenImageModal from "./FullScreenImageModal.jsx";

// ImageProcessor Component
const ImageProcessor = ({
  LeftComponent,
  MiddleComponent,
  targetTitle,
  images,
  modelImages,
  sourceTitle,
  onFaceSelect,
  onModelSelect,
  onRun,
  selectedFace,
  selectedModel,
  outputImage,
  isRunning,
}) => {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  return (
    <div
      className={`${isSidebarOpen ? "responsive-img-p" : ""} mt-16 bg-[#222226] transition-all duration-200 rounded-xl`}
    >
      <div className="flex flex-col lg:flex-row">
        {/* Left Section */}
        <LeftComponent
          sourceTitle={sourceTitle}
          targetTitle={targetTitle}
          images={images}
          modelImages={modelImages}
          onFaceSelect={onFaceSelect}
          onModelSelect={onModelSelect}
          isSidebarOpen={isSidebarOpen}
          openFullscreenImage={openFullscreenImage}
        />

        {/* Middle Section */}
        <MiddleComponent
          outputImage={outputImage}
          isRunning={isRunning}
          openFullscreenImage={openFullscreenImage}
        />

        {/* Right-section */}
        <RightDock
          onRun={onRun}
          selectedFace={selectedFace}
          selectedModel={selectedModel}
          isRunning={isRunning}
          outputImage={outputImage}
          feature={"face-swap"}
        />

        {/* Fullscreen Modal */}
        <FullscreenImageModal
          imageUrl={fullscreenImage}
          onClose={closeFullscreenImage}
        />
      </div>
    </div>
  );
};

export default ImageProcessor;
