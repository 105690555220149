import React, { useState } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "lucide-react";

const Button = ({ onClick, Icon, label }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <button
        onClick={onClick}
        className={`p-2 text-white flex flex-col items-center justify-center rounded transition border focus:outline-none focus:ring-1 focus:ring-yellow-400 focus:text-yellow-400 focus:border-yellow-400 w-28 h-16`}
      >
        <Icon size={28} className="" />
        <span className="mt-1">{label}</span>
      </button>
    </div>
  );
};

const Counter = ({ initialCount = 0, step = 1 }) => {
  const [count, setCount] = useState(initialCount);

  const increase = () => setCount(count + step);
  const decrease = () => setCount(count - step);

  return (
    <div className="flex flex-col items-center justify-center p-6 space-y-4">
      <div className="flex items-center space-x-4">
        <Button onClick={increase} Icon={MinusCircleIcon} label="Erase" />
        <Button onClick={decrease} Icon={PlusCircleIcon} label="Restore" />
      </div>
    </div>
  );
};

export default Counter;
