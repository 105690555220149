import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import Profile from "./Auth/Profile";
import Dashboard from "./Dashboard/Dashboard";
import Banner from "./Dashboard/Banner.jsx";
import Pagenotfound from "./Errors/Pagenotfound";
import Pricing from "./Pricing/Pricing.tsx";
import Galary from "./ContentLibrary/GallaryPage.jsx";
import UploadImagePage from "./ContentLibrary/UploadImage.jsx";
import Catalogue from "./Catalogue/Catalogue.jsx";
import Forgot from "./Auth/Forgot";
import Studio from "./Dashboard/Studio.jsx";
import Faceswap from "./ImageProcessing/face-swap/Faceswap";
import GenrateCatalogue from "./GenrateCatalogue/GenrateCatalogue.jsx";
import { UnVerifiedAccount } from "./Auth/UnVerifiedAccount";
import VerifyEmail from "./Auth/VerifyEmail";
import PasswordReset from "./Auth/PasswordReset";
import { UserProvider } from "./Auth/UserContext";
import { getCookie } from "../api/utils/cookies";
import BackgroundSwap from "./ImageProcessing/BackgroundSwap/Backgroundswap.jsx";
import ApparelSwap from "./ImageProcessing/ApparelSwap/ApparelSwap.jsx";
import DescriptionGenerator from "./ImageProcessing/DescriptionGenerator/DescriptionGen.jsx";
import ImageToVideo from "./Imagetovideo/Imagetovideo.jsx";
import BgImageGenerator from "./ImageProcessing/Generator/Bg-Image-generator/Bg-generator.jsx";
import ModelGenerator from "./ImageProcessing/Generator/model-generator/Model-Generator.jsx";
import ModelSwap from "./ImageProcessing/modelSwap/ModelSwap.jsx";
import BackgroundFill from "./ProductCatalogue/BackgroundFill/BackgroundFill.jsx";
import ProductTryon from "./ProductCatalogue/ProductTryon/ProductTryon.jsx";
import ProductFill from "./ProductCatalogue/PropFill/PropFill.jsx";
import ObjectRemove from "./ProductCatalogue/ObjectRemover/ObjectRemover.jsx";
const ProtectedRoute = ({ children }) => {
  const token = getCookie("token");
  if (!token) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const RootRedirect = () => {
  const token = getCookie("token");
  if (token) {
    return <Navigate to="/dashboard/home" replace />;
  }
  return <Login />;
};

const Routers = () => {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootRedirect />} />
          <Route path="/login" element={<Login />} />
          <Route path="/social/google/login/callback" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/unverified-account" element={<UnVerifiedAccount />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset-password" element={<PasswordReset />} />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<Navigate to="app" replace />} />
            <Route path="home" element={<Banner />} />
            <Route path="app" element={<Studio />} />
            {/* <Route path="poseswap" element={<PoseSwap />} /> */}
            <Route path="profile" element={<Profile />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="catalogue" element={<Catalogue />} />
            <Route path="generate-catalogue" element={<GenrateCatalogue />} />
            <Route path="gallery" element={<Galary />} />
            <Route path="galary/UploadImage" element={<UploadImagePage />} />
            <Route path="faceswap" element={<Faceswap />} />
            <Route path="bg-swap" element={<BackgroundSwap />} />
            <Route path="apparel-swap" element={<ApparelSwap />} />
            <Route path="image-to-video" element={<ImageToVideo />} />
            <Route
              path="description-generator"
              element={<DescriptionGenerator />}
            />
            <Route path="bg-generator" element={<BgImageGenerator />} />
            <Route path="model-generator" element={<ModelGenerator />} />
            <Route path="model-swap" element={<ModelSwap />} />
            <Route path="bg-fill" element={<BackgroundFill />} />
            <Route path="product-try-on" element={<ProductTryon />} />
            <Route path="prop-fill" element={<ProductFill />} />
            <Route path="object-remover" element={<ObjectRemove />} />
          </Route>
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
};

export default Routers;
