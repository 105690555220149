import { useState, useEffect, useRef } from "react";
import FileUploader from "./FileUploader.jsx";
import FilterForm from "./FilterForm.jsx";
import PhotoLibrary from "./PhotoLibrary.jsx";
import { motion } from "framer-motion";

import { ChevronDown, ChevronUp, SlidersHorizontal } from "lucide-react";

const ImageSection = ({
  title,
  images,
  selectedImage,
  onImageSelect,
  onImageUpload,
  onReset,
  renderLoadingPlaceholders,
  type,
}) => {
  const [showLibrary, setShowLibrary] = useState(false);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const filterFormRef = useRef(null);

  const toggleLibrary = () => setShowLibrary((prev) => !prev);

  const toggleFilterForm = (e) => {
    e.stopPropagation();
    setShowFilterForm((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterFormRef.current &&
        !filterFormRef.current.contains(event.target)
      ) {
        setShowFilterForm(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full p-4 ">
      <h2 className="text-white mb-4">{title}</h2>
      <div className="mb-4">
        <div className="mb-4">
          <FileUploader handleImageUpload={onImageUpload} />
          <p className="text-customGray text-xs lg:text-center my-4">
            Or select from photo library
          </p>
        </div>
      </div>

      <div className="text-white flex justify-between items-center mb-4 cursor-pointer">
        <h1 onClick={toggleLibrary}>Photo Library</h1>
        <div className="flex items-center gap-3">
          {showLibrary && (
            <SlidersHorizontal
              onClick={toggleFilterForm}
              className="hover:text-grayBG3 transition-all duration-300"
              size={16}
            />
          )}
          {showLibrary ? (
            <ChevronUp
              className="hover:text-grayBG3 transition-all duration-300"
              onClick={toggleLibrary}
            />
          ) : (
            <ChevronDown
              className="hover:text-grayBG3 transition-all duration-300"
              onClick={toggleLibrary}
            />
          )}
        </div>
      </div>

      {showFilterForm && (
        <motion.div
          ref={filterFormRef}
          initial={{ opacity: 0, scale: 0.9, y: "-50%", x: "-50%" }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }}
          className="absolute z-20 top-1/2 rounded-lg w-[300px] left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          <FilterForm type={type} onClose={() => setShowFilterForm(false)} />
        </motion.div>
      )}

      {showLibrary && (
        <PhotoLibrary
          images={images}
          currentImageIndex={currentImageIndex}
          selectedFace={selectedImage}
          handleFaceImageSelect={onImageSelect}
          renderLoadingPlaceholders={renderLoadingPlaceholders}
          handleImageUpload={onImageUpload}
          handleReset={onReset}
        />
      )}
    </div>
  );
};

export default ImageSection;
