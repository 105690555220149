import React from "react";
import download from "../../assets/images/download.svg";
import { axiosInstanceUnAuthenticated } from "../../api/axiosConfig";
import { Download } from "lucide-react";

export const DownloadButton = ({ imageUrl }) => {
  const downloadImage = async () => {
    try {
      // Create a fetch request to get the blob
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Create a temporary link to download the blob
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "downloaded_image.png"; // Or extract filename from URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
      alert("Failed to download image");
    }
  };

  return (
    // <button onClick={downloadImage} className="download-btn">
    //   Download Image
    // </button>
    <button
      onClick={downloadImage}
      disabled={!imageUrl}
      className=" text-black bg-customYellow w-full py-3 px-8 rounded-md cursor-pointer hover:opacity-70 transition-opacity flex gap-2 items-center justify-center"
    >
      <Download size={16} />
      <span className="text-sm">Download</span>
    </button>
  );
};
