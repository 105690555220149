import React from "react";
import { X } from "lucide-react";
import { motion } from "framer-motion";

const FullscreenImageModal = ({ imageUrl, onClose }) => {
  if (!imageUrl) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      onClick={onClose}
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="relative w-full h-full max-w-4xl max-h-4xl"
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 z-60 bg-white/20 hover:bg-white/40 rounded-full p-2"
        >
          <X color="white" size={24} />
        </button>
        <motion.img
          src={imageUrl}
          alt="fullscreen"
          className="w-full h-full object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </motion.div>
    </motion.div>
  );
};

export default FullscreenImageModal;
